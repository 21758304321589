<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else>
      <div v-if="teacherPractice_form.students.length">
        <h4 class="mt-4 text-center">Список студентов (практика)</h4>


        <!-- Table Start -->
        <DataTable class="mt-4" :value="teacherPractice_form.students" :paginator="true" :rows="50"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[50, 100, 200]"
                   filterDisplay="menu"
                   :globalFilterFields="['barcode','last_name','first_name','middle_name']"
                   v-model:filters="filters"
                   currentPageReportTemplate="Показано {last} из {totalRecords}"
                   stripedRows responsiveLayout="scroll">

          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
              </span>
            </div>
          </template>


          <Column field="barcode" header="Баркод"></Column>

          <Column header="ФИО">
            <template #body="{data}">
              <div v-if="data.middle_name == null">
                {{ capitalizeFirstLetter(data.last_name) }} {{ capitalizeFirstLetter(data.first_name) }}
              </div>
              <div v-else>
                {{ capitalizeFirstLetter(data.last_name) }} {{ capitalizeFirstLetter(data.first_name) }}
                {{ capitalizeFirstLetter(data.middle_name) }}
              </div>

            </template>
          </Column>
          <Column field="student_groups_name" header="Группа"></Column>
          <Column field="discipline_name" header="Дисциплина"></Column>
          <Column header="Оценка">
            <template #body="{data}">

              <div v-if="data.score == null">
                <Button label="Оценка" @click="openAddScoreDialog(data.student_id, data.formation_id)"/>
              </div>
              <div v-else>
                <b>{{data.score}}</b>
              </div>


            </template>
          </Column>


        </DataTable>
        <!-- Table End -->

      </div>
      <h5 v-else class="text-center mt-4">Студенты не прикреплены</h5>

    </div>

    <Dialog :header="'Поставить оценку студенту ' + studentName" :visible="addScoreDisplay" :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}" :closable="false">


      <div class="row mt-4">
        <div class="col-md-3">
          <p>Оценка</p>
        </div>
        <div class="col-md-9">
          <input class="form-control" type="number" placeholder="Оценка"
                 @change="changeScore($event)">
        </div>
      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeAddScoreDialog" class="p-button-text"/>
        <Button label="Сохранить" icon="pi pi-check" :loading="btnSaveDisabled" @click="submitScore"/>
      </template>
    </Dialog>

  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "TeacherPracticeGrades",
  data() {
    return {
      loading: true,
      addScoreDisplay: false,
      btnSaveDisabled: false,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'barcode': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'last_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'first_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'middle_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },

      },
      // students: [
      //   {
      //     id: 1,
      //     barcode: 1811147,
      //     student_id: 2656,
      //     last_name: 'Иванов',
      //     first_name: 'Иван',
      //     middle_name: 'Иванович',
      //     student_group: 'Группа',
      //     practice_grade: 0,
      //     formation_education_program: 56,
      //     semester: 8,
      //     credit: 12
      //   },
      //   {
      //     id: 2,
      //     barcode: 1910595,
      //     student_id: 2845,
      //     last_name: 'Артемов',
      //     first_name: 'Артем',
      //     middle_name: 'Артемович',
      //     student_group: 'Группа 12',
      //     practice_grade: 0,
      //     formation_education_program: 57,
      //     semester: 6,
      //     credit: 8
      //   }
      // ],
      student_id: null,
        formation_id: null,
      score: null,
      studentName: '',
    }
  },
  computed: {
    ...mapState('teacherPractice', ['teacherPractice_form']),
  },

  methods: {
    ...mapActions('teacherPractice', ['GET_PRACTICE_STUDENTS', 'POST_PRACTICE_RATINGS']),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    openAddScoreDialog(student_id, formation_id) {
      this.student_id = student_id
      this.formation_id = formation_id
      const student = this.teacherPractice_form.students.find(i => i.student_id === student_id)

      if (student) {
        this.studentName = student?.last_name + ' ' + student?.first_name
      }
      this.addScoreDisplay = true
    },
    closeAddScoreDialog() {
      this.addScoreDisplay = false
      this.student_id = null
      this.formation_id = null
      this.score = null
    },
    changeScore(e) {
      this.score = e.target.value
      console.log(this.score, 'score')
    },

    async submitScore() {
      this.addScoreDisplay = false
      this.btnSaveDisabled = true

        let ratingsData = this.teacherPractice_form.students.find(i=>i.student_id == this.student_id && i.formation_id == this.formation_id)

        // let form = {}
        // form['student_id'] = this.student_id
        // form['score'] = this.score
        // form['formation_education_program'] = ratingsData.formation_id
        // form['semester'] = ratingsData.semester
        // form['credit'] = ratingsData.credit
        let form = []
        form.push({
          student_id: this.student_id,
          score: this.score,
          formation_education_program: this.formation_id,
          semester: ratingsData.semester,
          credit: ratingsData.credit,
        })
        console.log(form, 'submit post form')


      const postRes = await this.POST_PRACTICE_RATINGS(form)
      if (postRes) {
        await this.GET_PRACTICE_STUDENTS()
        this.$message({title: 'Успешно сохранено'})
        this.student_id = null
        this.formation_id = null
        this.score = null
      }



      this.btnSaveDisabled = false


    },

  },
  async mounted() {

    await this.GET_PRACTICE_STUDENTS()
    this.loading = false
  }

}
</script>

<style scoped>

</style>
